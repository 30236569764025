<template>
  <div>
    <add-organizer
      v-if="showAddOrganizer"
      :showModal="showAddOrganizer"
      :onClickCancel="hideModal"
    />

    <delete-organizer
      v-if="showDeleteOrganizer"
      :showModal="showDeleteOrganizer"
      :organizer="selectedOrganizer"
      :onClickCancel="hideModal"
    />

    <edit-organizer
      v-if="showEditOrganizer"
      :showModal="showEditOrganizer"
      :organizer="selectedOrganizer"
      :onClickCancel="hideModal"
    />

    <ui-component-modal
      :modalTitle="$t(`Components.Events.EventOrganizer.Manage.Main_Title`)"
      modalSize="large"
      :buttonLabelConfirm="$t(`Form.Control.Close`)"
      :hideCancelButton="true"
      :onClickSave="onClickCancel"
      :onClickCancel="onClickCancel"
      :showModal="showModal"
      @closeModal="onClickCancel"
    >
      <template v-slot:content>
        <div class="has-margin-bottom">
          <message
          :message="$t('Components.Events.EventOrganizer.Manage.Message_OrganizerInfo')"
          />
        </div>
        <div class="actionBar has-background-white-ter has-padding has-margin-bottom">
          <div class="level">
            <div class="level-left"></div>
            <div class="level-right">
              <a @click="setShowAddOrganizer">
                <span class="icon">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </span>
                <span>{{ $t(`Form.Control.Create`)}}</span>
              </a>
            </div>
          </div>
        </div>

        <div class="display-organizers">
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th>{{$t(`Components.Events.EventOrganizer.Add.Label_Name`)}}</th>
                <th>{{$t(`Components.Events.EventOrganizer.Add.Label_Email`)}}</th>
                <th>{{$t(`Components.Events.EventOrganizer.Add.Label_Phone`)}}</th>
                <th>{{$t(`Components.Events.EventOrganizer.Manage.Label_Primary`)}}</th>
                <th>{{$t(`Components.Events.EventOrganizer.Manage.Label_ReceiveMail`)}}</th>
                <th>{{$t(`Components.Events.EventOrganizer.Manage.Label_Image`)}}</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(organizer, index) in eventData.Organizers"
                :key="index"
              >
                <td>{{ organizer.Name }}</td>
                <td>{{ organizer.Email }}</td>
                <td>{{ organizer.Phone }}</td>
                <td>
                  <span class="icon">
                    <font-awesome-icon
                      v-if="organizer.IsPrimary"
                      :icon="['fas', 'check']"
                      class="has-text-success"
                    />
                    <font-awesome-icon
                      v-if="!organizer.IsPrimary"
                      :icon="['fas', 'times']"
                      class="has-text-danger"
                    />
                  </span>
                </td>
                <td>
                  <span class="icon">
                    <font-awesome-icon
                      v-if="organizer.ReceiveCheckinMail"
                      :icon="['fas', 'check']"
                      class="has-text-success"
                    />
                    <font-awesome-icon
                      v-if="!organizer.ReceiveCheckinMail"
                      :icon="['fas', 'times']"
                      class="has-text-danger"
                    />
                  </span>
                </td>
                 <td>
                  <span class="icon">
                    <font-awesome-icon
                      v-if="organizer.Image"
                      :icon="['fas', 'check']"
                      class="has-text-success"
                    />
                    <font-awesome-icon
                      v-if="!organizer.Image"
                      :icon="['fas', 'times']"
                      class="has-text-danger"
                    />
                  </span>
                </td>
                <td>
                  <a
                    @click="setShowEditOrganizer(organizer)"
                    class="has-icon"
                  >
                    <span class="icon">
                      <font-awesome-icon :icon="['fas', 'edit']" />
                    </span>
                  </a>

                  <a
                    v-if="eventData.Organizers.length!== 1 && !organizer.IsPrimary"
                    @click="setShowDeleteOrganizer(organizer)"
                    class="has-icon has-text-danger"
                  >
                    <span class="icon">
                      <font-awesome-icon :icon="['fas', 'trash-alt']" />
                    </span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </ui-component-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Message from '@/components/UI/Message'

const ModalAddOrganizer = () =>
  import('@/components/Events/ModalAddEventOrganizer')
const ModalDeleteOrganizer = () =>
  import('@/components/Events/ModalDeleteEventOrganizer')
const ModalEditOrganizer = () =>
  import('@/components/Events/ModalEditEventOrganizer')

export default {
  components: {
    'add-organizer': ModalAddOrganizer,
    'delete-organizer': ModalDeleteOrganizer,
    'edit-organizer': ModalEditOrganizer,
    message: Message
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      selectedOrganizer: null,
      showAddOrganizer: false,
      showDeleteOrganizer: false,
      showEditOrganizer: false,
    }
  },

  computed: {
    ...mapState('eventStore', ['eventData']),
  },

  created() { },

  methods: {
    setShowAddOrganizer() {
      this.showAddOrganizer = true
    },

    setShowDeleteOrganizer(organizer) {
      this.selectedOrganizer = organizer
      this.showDeleteOrganizer = true
    },

    setShowEditOrganizer(organizer) {
      this.selectedOrganizer = organizer
      this.showEditOrganizer = true
    },

    hideModal() {
      this.selectedOrganizer = null
      this.showAddOrganizer = false
      this.showDeleteOrganizer = false
      this.showEditOrganizer = false
    },
  },
}
</script>
